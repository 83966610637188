<template>
  <div>
      <label @click='increaseWidth' class='ml-3 Download' style='margin-bottom:0px'  
      for="modal-switch" role="button" data-toggle="modal" data-target="#myModal">
      <span class="btn-pri float-right">
                       {{title}}</span>
      </label>

                  <!-- <button >Read</button> -->
                  <div class="pure-css-bootstrap-modal">
    <input type="checkbox" id="modal-switch"/>


  <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" v-if='pdfData !== null'>
    <label class="modal-backdrop fade" for="modal-switch"></label>

    <div class="modal-dialog" role="document">
      <div class="modal-content" :style='{height:ht+"vh",top:"-50px"}'>
      <vue-pdf-app page-scale="70" :page-number="2"  :pdf="pdfData"></vue-pdf-app>
      </div>
    </div>
  </div>
</div>
  </div>
</template>

<script>
import axios from "axios";
import VuePdfApp from "vue-pdf-app";
// import this to use default icons for buttons
import "vue-pdf-app/dist/icons/main.css";
export default {
    components: {
    VuePdfApp
  },
  props:['title'],
    data(){
        return {
            ht:0,
            pdfData:null,
        }
    },
    mounted(){
      axios.get("https://assets-hxy.fra1.digitaloceanspaces.com/claimelly/wp.pdf",{ responseType: 'arraybuffer' })
      .then((res) => {
        this.pdfData = res.data
        console.log(res)
      })
    },
    methods:{
        increaseWidth() {
            console.log('increaseing width in 2 seconds')
            setTimeout(() => {
            this.ht = 85;
                
            }, 2000);
        },
    }
}
</script>

<style>

</style>